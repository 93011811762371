import React, {useState} from "react";
import "../../../../../sass/global/modals.scss";
import moment from "moment";


export default function CancelOrderModal({modalVisible, closeModal, registration, requestType, handleSubmitted}) {

    const [loading, setLoading] = useState('');
    const [text, setText] = useState('');

    const handleForm = () => {
        setLoading(true);
        const data = new FormData;
        data.append('text', text);
        data.append('type', requestType);

        axios.post(`/api/${registration.id}/support-request`, data)
            .then(response => {
                setText('');
                closeModal();
                handleSubmitted();
            })
            .catch(e => console.error(e))
            .finally(() => setLoading(false))
    }

    const cancellationMessage = () => {
        const daysDiff = moment(registration.conference.dates[0].date).diff(moment(), 'days') + 1;

        if (!registration.paid) return 'Odoslaním tejto žiadosti bude vaša objednávka zrušená. Potvrdenie dostanete emailom.';
        if (registration.paid && daysDiff >= 14) return 'Odoslaním tejto žiadosti bude vaša objednávka zrušená. Potvrdenie dostanete emailom.';
        if (registration.paid && daysDiff < 14 && daysDiff >= 7) return 'Odoslaním tejto žiadosti bude vaša objednávka zrušená. Potvrdenie dostanete emailom.';
        if (registration.paid && daysDiff < 7) return 'Odoslaním tejto žiadosti bude vaša objednávka zrušená. Potvrdenie dostanete emailom.';
    }

    return (
        <>
            {modalVisible === registration.id && (
                <>
                    <div className="backdrop"></div>
                    <div className="modal modal--m">
                        <div className="modal__header pb--0">
                            <h4 className="mb--s"><i
                                className="fad fa-exclamation-triangle mr--xs text--danger"></i> Zrušiť objednávku
                            </h4>

                            <button className={"modal__close"}
                                    onClick={closeModal}
                            >
                                <i className="fal fa-times"></i>
                            </button>
                        </div>
                        <form onSubmit={e => {
                            e.preventDefault();
                            if (loading) return;
                            handleForm();
                        }}>
                            <div className="modal__body">
                                <div className="modal__full gray">
                                    <p>{cancellationMessage()}</p>
                                    <div className="input-container mt--s">
                                        <label className={'input__label'}>Budeme radi, ak nám napíšete dôvod zrušenia objednávky</label>
                                        <textarea className="input__item textarea l"
                                                  value={text}
                                                  onChange={e => {
                                                      setText(e.target.value)
                                                  }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="modal__footer">

                                <button
                                    className={"btn btn--text btn--xs mr--xs"}
                                    disabled={loading}
                                    onClick={() => {
                                        if (loading) return;
                                        closeModal()
                                    }}
                                >
                                    Zrušiť
                                </button>

                                <button
                                    type="submit"
                                    disabled={loading}
                                    className={"btn btn--danger btn--xs btn--shadow"}
                                >
                                    {
                                        loading ? (
                                            <>
                                                <i className="fas fa-spinner fa-spin"/>
                                                &nbsp;
                                                Odosielam...
                                            </>
                                        ) : 'Odoslať'
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </>
            )
            }
        </>
    )
}

